<template>
  <div id="quotation-list">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row> 
              <b-col xl="4" sm="12">
                <b-form-group label="Titulo" label-for="status">
                  <b-form-input  id="general"
                  v-model="filtersForm.title"
                  name="general"
                  placeholder=""
                  @change="sendSearchByTitle()"
                  />
                </b-form-group>
                </b-col>

                <b-col xl="4" sm="12">
                  <b-form-group label="Fecha" label-for="status">
                    <b-form-datepicker
                    locale="es"
                    id="date_ini" 
                    reset-button
                    placeholder="Selecciona una fecha"
                    v-model="filtersForm.date"/>
                  </b-form-group>
                </b-col>

              <b-col xl="4" sm="12">
                  <b-form-group label="Estado" label-for="status">
                    <v-select
                      v-model="filtersForm.status"
                      label="name"
                      placeholder="Selecciona un Estado"
                      :options="statusList"
                      :reduce="statusList => statusList.id">
                      <span slot="no-options">
                        No se encontraron opciones
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
 
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay
              :show="overlayList"
              opacity="1.0"
              spinner-small
              spinner-variant="primary"
              @hidden="_onHidden()">
              <div class="d-flex align-items-end justify-content-end">
                <div>  
                  <b-button variant="primary"
                    @click.prevent="_createNews()">
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Nueva Noticia
                    </span>
                  </b-button>
                </div>
              </div>
              <div class="mt-2">
                <b-table
                  hover
                  striped
                  show-empty
                  responsive="xl"
                  class="position-relative mb-1"
                  :items="news.data"
                  :fields="tableColumns"
                  empty-text="Datos no Disponibles">
                  <template #cell(created_at)="data">
                    {{ data.item.created_at | moment("DD-MM-YYYY") }}
                  </template> 

                  <template #cell(status)="data">
                  <b-form-checkbox
                    switch
                    inline
                    name="check-status"
                    v-model="data.item.visible"
                    @change="_changeVisibleData(data.item)" 
                    />  
                </template>
                <template #cell(date)="data">
                  {{ data.item.date | moment("DD-MM-YYYY") }}
                </template>

                

                  
                  <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret
                      :right="$store.state.appConfig.isRTL">
                      <template #button-content>
                        <feather-icon size="16"
                          icon="MoreVerticalIcon"
                          class="align-middle text-body"/>
                      </template>

                      <b-dropdown-item @click.prevent="_editNew(data.item)" v-if="permissions_visible.edit_news">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item> 
                      

                  </b-dropdown>
                </template>

              </b-table>
                <b-pagination
                  align="right"
                  v-model="currentPage"
                  hide-goto-end-buttons
                  :total-rows="news.total"
                  :per-page="news.per_page"
                  />
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { forEach } from 'postcss-rtl/lib/affected-props';

  export default {
    name: 'NewsList',
    data () {
      return {
      is_admin:false,
      permissions_visible:{
        edit_preapprovals:false,
        show_preapprovals:false,
        download_preapprovals:false,
        edit_news:false,
      },
        overlayForm: false,
        overlayList: true,
        currentPage: 1,
        filtersForm: { 
          status: '',
          page: 1, 
          title:'',
          date:'',
        },
        statusList: [
          {id:'1',name:'Visible'},
          {id:'0',name:'Oculto'},
        ],
        tableColumns: [
          {key: 'id', sortable: false, label: 'ID'},
          {key: 'title', sortable: true, label: 'Titulo'},
          // {key: 'customer.last_name', sortable: true, label: 'Apellido'},
          // {key: 'customer.phone', sortable: true, label: 'Télefono'},
          // {key: 'project.name', sortable: true, label: 'Proyecto'},
          // {key: 'project.commune.name', sortable: true, label: 'Comuna'},
          {key: 'source', sortable: true, label: 'Fuente'},
          {key: 'status', sortable: true, label: 'Estado'},
          {key: 'date', sortable: true, label: 'Fecha'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ]
      }
    },
    mounted(){
      this.is_admin = this.$PermissionHelper.getRole().toLowerCase() == 'admin';
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      if(this.is_admin){
        this.permissions_visible = {
          edit_preapprovals:true,
          show_preapprovals:true,
          download_preapprovals:true,
          edit_news:true,
        };
      }
    },
    beforeMount() {
      // this.filtersForm.page = this.currentPage
      this._fetchNews(this.filtersForm)
    },
    watch: {
      "filtersForm.status": {
        handler(newval){
          this._fetchNews(this.filtersForm)
        }
      },
      "filtersForm.date": {
        handler(newval){
          this._fetchNews(this.filtersForm)
        }
      },
      "filtersForm.title": {
        handler(newval){
          this._fetchNews(this.filtersForm)
        }
      },
      currentPage (val) {
        this.filtersForm.page = this.currentPage
        this._fetchNews(this.filtersForm)
      }
    },
    computed: {
      ...mapState('news',['news']),
      ...mapGetters('profile',['projects']),
    },
    methods: {
      ...mapActions('news',['exportNews','fetchNews','changeVisible']),
      sendSearchByTitle(){
        this._fetchNews(this.filtersForm);
      },
      async _fetchNews(filter) {
        this.overlayList = true
        await this.fetchNews(filter)
        .then((res) => {
           
        })  
        .finally(() => {
          this.overlayList = false
        })
      },

       async _changeVisibleData(news) {
        let visibleForm = {id: news.id, status: news.visible === true ? '1':'0'}
        //news.status = visibleForm.status;
        await this.changeVisible(visibleForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Estado modificado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Problemas al modificar el estado',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
          })
      },
       
      async _exportPreapprovals() {
        this.overlayForm = true
        await this.exportNews(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      _editNew(newobj) {
        let data = { name: 'news-details', params: { id: newobj.id, newsingle: newobj }};
        this.$router.push( data )
      },
      _onHidden() {
        //this.$refs.button.focus()
      },
      _colorBadge (data) {
        switch (data) {
          case '1':
            return 'light-success'
          case '0':
            return 'light-warning'
          case '2':
            return 'light-danger'
        }
      },
      _nameBadge (data) {
        switch (data) {
          case '1':
            return 'Aprobado'
          case '0':
            return 'Pendiente'
          case '2':
            return 'Rechazado'
        }
      },
      _createNews() {
        this.$router.push({ name: 'news-create' })
      },
    },
  }
</script>
