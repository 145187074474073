<template>
  <div id="news">
    <news-list></news-list>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import NewsList from './components/NewsList'

  export default{
    name: 'NewsView',
    components: {NewsList},
    beforeMount() {
      this.fetchProjects()
    },
    methods: {
      ...mapActions('profile',['fetchProjects']),
    },
  }
</script>
